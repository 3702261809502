// Custom Imports
import * as ACTIONTYPES from "./../../action-types";
import * as UTILS from "./../../../utils";

const initState = {
  student_signup_data: {
    student_profile: {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      gender: "",
      country_id: "",
      state: "",
      city: "",
      date_of_birth: new Date(),
      zip_code: "",
      street_address: "",
      term_of_use: false,
      photo: "",
      step: 1,
    },
    school_profile: {
      school_id: "",
      school_name: "",
      school_email:"",
      school_type_id: "",
      country_id: "",
      state: "",
      city: "",
      zip_code: "",
      street_address: "",
      contact: "",
      user_role_id: "",
      term_of_use: false,
      step: 2,
      school_logo:""
    },
    password: "",
    password_confirmation: "",
    step: 3,
  },
  tutor_signup_data: {
    tutor_profile: {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      gender: "",
      country_id: "",
      state: "",
      city: "",
      date_of_birth: new Date(),
      zip_code: "",
      street_address: "",
      term_of_use: false,
      photo: "",
      step: 1,
    },
    password: "",
    password_confirmation: "",
    step: 3,
  },
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTIONTYPES.GET_STUDENT_SIGNUP_DATA:
      return getStudentSignupData(state, action);
    case ACTIONTYPES.SET_STUDENT_SIGNUP_DATA:
      return setStudentSignupData(state, action);
  
    case ACTIONTYPES.GET_TUTOR_SIGNUP_DATA:
      return getTutorSignupData(state, action);
    case ACTIONTYPES.SET_TUTOR_SIGNUP_DATA:
      return setTutorSignupData(state, action);
    default:
      return state;
  }
};

const getStudentSignupData = (state, action) => {
  return initState;
};

const setStudentSignupData = (state, action) => {
  if (action.payload) {
    const updatedState = { ...state };
    updatedState.student_signup_data = action.payload;
    return updatedState;
  } else {
    return initState;
  }
};

const getTutorSignupData = (state, action) => {
  return initState;
};

const setTutorSignupData = (state, action) => {
  if (action.payload) {
    const updatedState = { ...state };
    updatedState.tutor_signup_data = action.payload;
    return updatedState;
  } else {
    return initState;
  }
};

export default authReducer;
